import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import nvphImg from "../../Assets/gallery/blogNVPHA.webp";
import fanbadaFrame from "../../Assets/gallery/1.webp";
import NvphFlatbed from "../../Assets/gallery/nflatbed.webp";
import climateImg from "../../Assets/gallery/climatePondicherry.webp";
import blog1Img from "../../Assets/gallery/blog1Img.webp";
import Blog6Img from "../../Assets/Blog6Img.webp";
import Commercial_Hydroponic_Img from "../../Assets/gallery/Commercial_Hydroponic_Img.webp";
import polyhouse_bog_img from "../../Assets/polyhouse_blog_img.webp";
import Hydroponics_types_img from "../../Assets/Hydroponics_types_img.webp";
import Top10_hydroponic_crops_img from "../../Assets/Top10_hydroponic_crops_img.webp";
import IntelliDose_img from "../../Assets/IntelliDose_img.webp";
import cucumber_blog from "../../Assets/cucumber_blog.webp";
import hydroponic_farm_img from "../../Assets/hydroponic_farm_img.webp";
import subsidy_img from "../../Assets/subsidy_img.webp";
import factory_img from "../../Assets/gallery/factory12.webp";
import why_choose_rgcpl_img from "../../Assets/why_choose_rgcpl_img.jpeg";
import cost_blog_img from "../../Assets/cost_blog_img.webp";
import "./blog.css";

function Blog() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      mirror: true,
      once: false,
      offset: 120,
    });
    AOS.refresh();
  }, []);
  return (
    <Container fluid>
      {/* Header Row */}
      <Row className="headingimg">
        <Col className="m-5">
          <h2 className="text-white m-4 mx-4 px-2 fw-bold text-center">
            Blogs
          </h2>
          <div className="text-white m-4 fw-bold text-center">
            <a href="/" className="text-white m-3 text-decoration-none">
              Home
            </a>
            <i className="fa-solid fa-circle-arrow-right"></i>
            <a href="#" className="text-white text-decoration-none m-3">
              Blogs
            </a>
          </div>
        </Col>
      </Row>
      {/* Blog Cards Row 1 */}
      <Row className="p-3 bg-dark">
        <Col
          md={4}
          className="mb-4"
          // data-aos="flip-right"
          // data-aos-delay="1000"
        >
          <div className="card h-100">
            <img
              src={why_choose_rgcpl_img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="hydroponics and polyhouse farms"
              style={{ height: "350px" }}
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
              Polyhouse Construction in India
              </h2>
              <div className="text-muted d-flex align-items-center mt-3">
                <i className="fa fa-calendar me-2"></i>
                <span>November 26, 2024</span>
              </div>
            </div>
            <div className="card-footer text-center">
              <Link
                to="/Polyhouse_construction_in_india"
                className="btn btn-success"
              >
                View More
              </Link>
            </div>
          </div>
        </Col>
        <Col
          md={4}
          className="mb-4"
          // data-aos="flip-right"
          // data-aos-delay="1000"
        >
          <div className="card h-100">
            <img
              src={cost_blog_img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="cost of commercial farm setups"
              style={{ height: "350px" }}
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
              Low Cost Polyhouse Setup
              </h2>
              <div className="text-muted d-flex align-items-center mt-3">
                <i className="fa fa-calendar me-2"></i>
                <span>September 22, 2024</span>
              </div>
            </div>
            <div className="card-footer text-center">
              <Link
                to="/Lowcost_polyhouse_setup"
                className="btn btn-success"
              >
                View More
              </Link>
            </div>
          </div>
        </Col>
        <Col md={4} className="mb-4">
          <div className="card h-100">
            <img
              src={nvphImg}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="nvph a frame"
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                NVPH A Frame
              </h2>
              <div className="text-muted d-flex align-items-center mt-3">
                <i className="fa fa-calendar me-2"></i>
                <span>September 01, 2024</span>
              </div>
            </div>
            <div className="card-footer text-center">
              <Link to="/NVPHBlog" className="btn btn-success">
                View More
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      {/* Blog Cards Row 2 */}
      <Row className="p-3 bg-dark">
        <Col md={4} className="mb-4">
          <div className="card h-100">
            <img
              src={hydroponic_farm_img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="Hydroponic farming"
              style={{ height: "350px" }}
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                Hydroponic Farming
              </h2>
              <div className="text-muted d-flex align-items-center mt-3">
                <i className="fa fa-calendar me-2"></i>
                <span>August 12, 2024</span>
              </div>
            </div>
            <div className="card-footer text-center">
              <Link to="/Hydroponic_Farming" className="btn btn-success">
                View More
              </Link>
            </div>
          </div>
        </Col>
        <Col md={4} className="mb-4">
          <div className="card h-100">
            <img
              src={subsidy_img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="Hydroponic farming"
              style={{ height: "350px" }}
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
              Polyhouse Subsidy Guidelines in India
              </h2>
              <div className="text-muted d-flex align-items-center mt-3">
                <i className="fa fa-calendar me-2"></i>
                <span>August 02, 2024</span>
              </div>
            </div>
            <div className="card-footer text-center">
              <Link
                to="/Polyhouse_subsidy_guidelines_in_india"
                className="btn btn-success"
              >
                View More
              </Link>
            </div>
          </div>
        </Col>
        <Col md={4} className="mb-4">
          <div className="card h-100">
            <img
              src={factory_img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="polyhouse farming"
              style={{ height: "350px" }}
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                Hydroponic Innovation in India
              </h2>
              <div className="text-muted d-flex align-items-center mt-3">
                <i className="fa fa-calendar me-2"></i>
                <span>July 25, 2024</span>
              </div>
            </div>
            <div className="card-footer text-center">
              <Link
                to="/Hydroponic_innovation_in_india"
                className="btn btn-success"
              >
                View More
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center p-3 bg-dark">
        <Col md={4} className="mb-4">
          <div className="card h-100">
            <img
              src={Top10_hydroponic_crops_img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="top 10 hydroponic setup companies"
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                Top 10 Hydroponic Plants for India
              </h2>
              <div className="text-muted d-flex align-items-center mt-3">
                <i className="fa fa-calendar me-2"></i>
                <span>June 20, 2024</span>
              </div>
            </div>
            <div className="card-footer text-center">
              <Link
                to="/Top10_hydroponic_plants_for_india"
                className="btn btn-success"
              >
                View More
              </Link>
            </div>
          </div>
        </Col>
        <Col md={4} className="mb-4">
          <div className="card h-100">
            <img
              src={IntelliDose_img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="Automated Dosing Systems"
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
              Automatic Dosing System for hydroponics
              </h2>
              <div className="text-muted d-flex align-items-center mt-3">
                <i className="fa fa-calendar me-2"></i>
                <span>June 05, 2024</span>
              </div>
            </div>
            <div className="card-footer text-center">
              <Link
                to="/Automatic_dosing_system_for_hydroponics"
                className="btn btn-success"
              >
                View More
              </Link>
            </div>
          </div>
        </Col>
        <Col md={4} className="mb-4">
          <div className="card h-100">
            <img
              src={cucumber_blog}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="infestation in cucumber"
              style={{ height: "350px" }}
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                Downy Mildew Infestation in Cucumber (🥒)
              </h2>
              <div className="text-muted d-flex align-items-center mt-3">
                <i className="fa fa-calendar me-2"></i>
                <span>May 18, 2024</span>
              </div>
            </div>
            <div className="card-footer text-center">
              <Link
                to="/Downy_Mildew_Infestation_in_Cucumber"
                className="btn btn-success"
              >
                View More
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center p-3 bg-dark">
        <Col md={4} className="mb-4">
          <div className="card h-100">
            <img
              src={Commercial_Hydroponic_Img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="Commercial Hydroponic farm setup in India "
            />
            <div className="card-body">
              <h1 className="card-title text-decoration-underline">
                Commercial Hydroponic Farming:
              </h1>{" "}
              <h2>A New Frontier in Agriculture</h2>
              <div className="text-muted d-flex align-items-center mt-3">
                <i className="fa fa-calendar me-2"></i>
                <span>April 12, 2024</span>
              </div>
            </div>
            <div className="card-footer text-center">
              <Link
                to="/Commercial_Hydroponic_Farming"
                className="btn btn-success"
              >
                View More
              </Link>
            </div>
          </div>
        </Col>
        <Col md={4} className="mb-4">
          <div className="card h-100">
            <img
              src={polyhouse_bog_img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="polyhouse farming setup"
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
              Fan and Pad Polyhouse Farming
              </h2>
              <div className="text-muted d-flex align-items-center mt-3">
                <i className="fa fa-calendar me-2"></i>
                <span>April 02, 2024</span>
              </div>
            </div>
            <div className="card-footer text-center">
              <Link
                to="/Fanpad_Polyhouse_Farming"
                className="btn btn-success"
              >
                View More
              </Link>
            </div>
          </div>
        </Col>
        <Col md={4} className="mb-4">
          <div className="card h-100">
            <img
              src={Hydroponics_types_img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="types of hydroponic systems"
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                Types of Hydroponic Systems
              </h2>
              <div className="text-muted d-flex align-items-center mt-3">
                <i className="fa fa-calendar me-2"></i>
                <span>March 15, 2024</span>
              </div>
            </div>
            <div className="card-footer text-center">
              <Link
                to="/Types_of_Hydroponics_Systems"
                className="btn btn-success"
              >
                View More
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center p-3 bg-dark">
        <Col md={4} className="mb-4">
          <div className="card h-100">
            <img
              src={NvphFlatbed}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="hydroponic farm"
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                NVPH Flatbed Hydroponics
              </h2>
              <div className="text-muted d-flex align-items-center mt-3">
                <i className="fa fa-calendar me-2"></i>
                <span>February 25, 2024</span>
              </div>
            </div>
            <div className="card-footer text-center">
              <Link to="/NVPHFlatbed_Blog" className="btn btn-success">
                View More
              </Link>
            </div>
          </div>
        </Col>
        <Col md={4} className="mb-4">
          <div className="card h-100">
            <img
              src={climateImg}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="hydroponics farms"
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                Hydroponic Farming Climate Conditions
              </h2>
              <div className="text-muted d-flex align-items-center mt-3">
                <i className="fa fa-calendar me-2"></i>
                <span>February 01, 2024</span>
              </div>
            </div>
            <div className="card-footer text-center">
              <Link to="/Hydroponic_farming_climate_conditions" className="btn btn-success">
                View More
              </Link>
            </div>
          </div>
        </Col>
        <Col md={4} className="mb-4">
          <div className="card h-100">
            <img
              src={Blog6Img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="hydroponic farming in india"
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                Hydroponic Farming in India:Revolution with
                Royal Green House
              </h2>
              <div className="text-muted d-flex align-items-center mt-3">
                <i className="fa fa-calendar me-2"></i>
                <span>January 12, 2024</span>
              </div>
            </div>
            <div className="card-footer text-center">
              <Link
                to="/Hydroponic_Farming_In_India"
                className="btn btn-success"
              >
                View More
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center p-3 bg-dark">
        <Col md={4} className="mb-4">
          <div className="card h-100">
            <img
              src={fanbadaFrame}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="fanpad a frame"
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                FAN-PAD A Frame
              </h2>
              <div className="text-muted d-flex align-items-center mt-3">
                <i className="fa fa-calendar me-2"></i>
                <span>November 15, 2023</span>
              </div>
            </div>
            <div className="card-footer text-center">
              <Link to="/FanpadAFrame_Blog" className="btn btn-success">
                View More
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
export default Blog;
